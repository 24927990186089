@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@200;300;400;500;600;700&family=JetBrains+Mono:wght@400&family=Sarabun:wght@300;400;500;600;700&display=swap');

body.app-body.-use-font {
    font-family: Prompt, Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif;
    //font-weight: 300;

    .text-content {
        overflow: visible;

        code {
            font-family: "JetBrains Mono", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        }
    }


    .swal2-icon {
        font-family: Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    }

    .un-inputbox {
        ._control + ._pend {
            @apply ml-2;
        }

        ._pend {
            @apply py-2;
            span {
                @apply leading-none;
            }
        }
    }

    .un-tag > span._text {
        @apply text-white;
    }
}
