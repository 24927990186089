/// -------------

.order-steps {
    @apply flex;
    > ._item {
        @apply flex flex-col items-center flex-1 relative cursor-pointer;

        &:after {
            @apply absolute z-0 h-2 bg-green-300 top-1/2 -left-1/2 right-1/2;
            content: " ";
        }

        &:first-child:after {
            @apply hidden;
        }

        &.-active {
            > ._icon {
                @apply text-green-500 font-bold;
            }

            > ._index {
                @apply bg-green-50 text-2xl leading-none text-green-600;
            }

            > ._name {
                @apply text-green-500 font-bold;
            }
        }

        &:hover {
            > ._icon {
                @apply text-orange-500;
            }

            > ._index {
                @apply border-orange-500 text-orange-500 bg-orange-50;
            }

            > ._name {
                @apply text-orange-500;
            }
        }

        > ._icon {
            @apply text-2xl;
        }

        > ._index {
            @apply flex items-center justify-center z-10 w-12 h-12 border-4 border-green-300 rounded-full bg-white font-bold leading-none;
        }

        > ._name {
            @apply text-grey-blue-400;
        }
    }
}


.order-form {
    .un-modal-neck {
        @apply border-b border-dashed;
    }

    .un-form-header {
        @apply w-60;
        .un-form-label {
            @apply text-left;
        }
    }

    .un-form-body {
        > .detail-item, > .un-form-item {
            > ._label {
                @apply w-48;
            }
        }
    }

    .un-form-item {
        > ._label {
            @apply w-44;
        }
    }

    ._customer-photo {

    }

    ._address-box {
        @apply p-4  bg-grey-blue-50 rounded-lg;
        .address-row {
            @apply mb-4;
        }
    }

    ._form-vehicle-search {
        @apply flex border p-2 bg-green-50 border-green-200 rounded-md mb-12;
        .un-inputbox {
            @apply flex-1 mr-4;
        }
    }
}

.order-form_driver {
    @apply border rounded-md p-4 pt-1;
    + .order-form_driver {
        @apply mt-4;
    }

    > ._header {
        @apply flex items-center mb-2;

        > ._title {
            @apply text-xl mr-auto;
        }

        > .un-input-checkbox:last-child {
            > .un-input-checkbox-item {
                @apply mr-0;
                > ._label {
                    @apply mr-0;
                }
            }
        }
    }

    > ._form {
        @apply flex;
        > ._photo {
            @apply flex flex-col w-40;
            > ._label {
                @apply mb-1 text-grey-500;
            }

            > .un-input-photo {
                @apply flex-1;
                > ._item {
                    @apply flex-1 h-auto;
                }
            }
        }

        > ._input {
            @apply flex-1;
        }
    }
}

.order-view-modal {
    .un-form-header {
        @apply w-60;
    }

    .un-form-body {
        > .detail-item, > .un-form-item {
            > ._label {
                @apply w-48;
            }
        }
    }
}

.order-view-detail {
    + .order-view-detail {
        @apply pt-2;
    }

    .un-form-body {
        > .detail-item {
            &:first-child {
                @apply mt-1;
            }
        }
    }
}

.order-view-qrpayment {
    @apply border rounded-md p-2 ml-4;
    > .detail-item {
        @apply my-0 text-sm;
        > ._label {
            @apply w-20;
        }
    }
}

.order-car-item {
    @apply flex;
    > ._item {
        @apply flex items-end border py-2 px-3 rounded;
        + ._item {
            @apply ml-2;
        }

        > ._label + ._value {
            @apply ml-1;
        }

        > ._value {
            @apply font-bold;
            + ._sub {
                @apply ml-2;
            }
        }

        > ._sub {
            @apply text-sm;
        }


        &.-grey {
            @apply bg-grey-50 text-grey-700;
        }

        &.-blue {
            @apply border-blue-200 bg-blue-50 text-blue-600;
            > ._value {
                @apply text-blue-700;
            }
        }

        &.-green {
            @apply border-green-200 bg-green-50 text-green-600;
            > ._value {
                @apply text-green-700;
            }
        }

        &.-red {
            @apply border-red-200 bg-red-50 text-red-600;
            > ._value {
                @apply text-red-700;
            }
        }
    }
}


.order-paylots {
    @apply grid grid-cols-4 gap-4 border-b border-b-grey-100 -mt-4 mb-4 pb-4;
    > ._table {
        &.-selected > .un-list-container {
            @apply ring-4 ring-blue-500 ring-opacity-25 border-blue-500;
            > ._header {
                @apply bg-blue-50 border-b-blue-200;
            }
        }

        > .un-list-container {
            &:hover {
                @apply cursor-pointer ring-4 ring-orange-500 ring-opacity-25 border-orange-500;
            }

            > ._header {
                @apply py-1 text-center bg-grey-blue-50 rounded-t border-b;
            }
        }
    }
}

/// -------------

.quotation-form-note {
    @apply border border-red-200 bg-yellow-50 rounded-md py-1 px-2 -mt-4 text-sm text-red-600;
    &.-grey {
        @apply border-grey-200 bg-grey-50 text-grey-600;
    }
}

.quotation-form-addon-note {
    @apply border border-red-200 bg-yellow-50 rounded-md py-1 px-2 text-sm text-red-600 self-center;
}

.quotation-form-insuretype {
    > .un-input-checkbox-item {
        @apply w-1/3 m-0;
    }
}

.quotation-form-driver {
    @apply flex items-start;
    > ._age {
        @apply ml-4 py-2 text-red-500;
    }
}


.quotation-offer-list {
    @apply flex-1 mr-4;
}

.quotation-offer-item {
    @apply flex border p-2 rounded-md cursor-pointer;

    &.-active {
        @apply bg-blue-50 border-blue-500 ring-4 ring-blue-200 ring-opacity-50;
    }

    &:hover {
        @apply border-orange-500 ring-4 ring-orange-200 ring-opacity-50;
    }

    + .quotation-offer-item {
        @apply mt-4;
    }

    > ._logo {
        @apply w-12 h-12 mr-4 mb-auto border rounded bg-contain bg-no-repeat bg-center;
    }

    > ._detail {
        @apply flex-1;

        > ._btn-drop {
            @apply float-right;
        }

        > ._header {
            @apply flex items-center;
            > ._name {
                @apply text-xl;
            }

            > ._paylot {
                @apply ml-2 text-red-500;
            }
        }

        > ._insure {
            @apply text-blue-600;
        }

        > ._money {
            @apply flex items-end;
            > ._item {
                @apply flex items-end;
                > ._label {
                    @apply text-grey-blue-500;
                }

                > ._value {
                    @apply ml-2 text-xl;
                }
            }

            > ._btn-select {
                @apply ml-4;
            }
        }
    }
}

.quotation-offer-form {
    @apply flex-1 border p-4 rounded-md;

    > ._head {
        @apply text-grey-blue-600 my-2 font-bold bg-grey-blue-50 rounded-md px-2;
    }

    > ._grid {
        @apply flex flex-wrap;
        > .un-form-item {
            @apply w-1/2;
        }
    }
}

.quotation-offer-view {
    @apply flex-1 border px-4 py-1 rounded-md;
    .detail-item {
        > ._label {
            @apply w-60 whitespace-pre-wrap;
        }
    }
}

.detail-item.unit-b > ._value, .detail-item .unit-b {
    @apply text-right;
    max-width: 180px;
}

.quotation-offer-header {
    @apply flex items-center border border-red-200 bg-red-50 mb-6 p-3 rounded-md;
    > ._icon {
        @apply text-4xl mx-4 text-red-500;
    }

    > ._name {
        @apply text-xl ml-2;
    }
}


.quotation-offer-compare {
    @apply relative -mx-3 -my-4;

    &.-border {
        @apply p-px border border-blue-200 rounded-md;
    }

    ._group.-group_main {
        @apply bg-blue-50;
        ._title {
            @apply rounded-b-none;
        }

        ._car {
            @apply rounded-b;
        }
    }

    ._title {
        @apply py-1 pl-2 text-white font-bold bg-blue-800 rounded;
    }

    ._car {
        @apply py-1 pl-2 text-white text-center font-bold bg-blue-200 text-blue-800;
    }

    ._item {
        @apply flex border-t border-dashed;
        &:first-child {
            @apply border-t-0;
        }

        &._bg-blue {
            @apply bg-blue-50;
        }

        &._bg-yellow {
            @apply bg-yellow-100;
        }

        &.-total {
            @apply text-xl bg-orange-100 rounded-md border-0 mt-2;
            > ._header {
                @apply text-orange-700 justify-end pr-4;
            }
        }

        > ._header {
            @apply flex items-center justify-start w-96 py-1 pl-2 font-bold text-blue-700;
        }

        > ._header_detail {
            @apply w-96 py-1 pl-2 text-white font-bold bg-blue-800;
        }

        > ._header_generation {
            @apply flex-1 py-1 pl-4 text-center text-blue-800 font-bold bg-white;
        }
    }

    ._value {
        @apply flex items-center justify-center border-l border-dashed flex-1 py-1 px-1;
        &.-max {
            @apply text-red-500;
        }

        &.-long {
            @apply text-sm text-left pl-4;
            white-space: pre-wrap;
        }
    }

    ._value-promotion {
        > div {
            @apply text-white bg-green-500 px-4 py-1 rounded-full;
            @each $color in $color-names {
                &.-prom_#{$color} {
                    @apply bg-#{$color}-500;
                }
            }
        }
    }

    ._value-company {
        @apply flex items-center justify-center;
        > ._logo {
            @apply w-12 h-12 mx-2 border rounded bg-contain bg-no-repeat bg-center;
        }

        > ._name {
            @apply flex-1 px-2 text-center text-lg;
        }
    }

    ._value-payrate {
        > ._lots {
            @apply ml-4 flex-1 text-sm text-grey-700 font-normal;
            > ._lot {
                @apply flex justify-center;
                > ._money {
                    @apply font-bold text-blue-600 ml-2;
                }
            }
        }
    }

    ._price {
        @apply text-xl text-red-500 font-bold;
    }

    ._img {
        @apply w-10 h-10;
        filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
    }
}

.quotation-offer-compares {
    @apply flex items-center mt-4;
}

.quotation-offer-compares-item {
    @apply relative flex rounded border bg-white p-0.5 mr-2;
    &:hover {
        > ._icon-drop {
            @apply block;
        }
    }

    > ._icon-drop {
        @apply hidden absolute -right-4 -top-4;
    }

    > ._logo {
        @apply w-9 h-9 mr-1 mb-auto border rounded bg-contain bg-no-repeat bg-center;
    }

    > ._detail {
        @apply pr-1;
        > ._company {
            @apply text-sm text-grey-blue-500;
        }

        > ._info {
            @apply text-xs;
            > span + span {
                @apply ml-1;
            }
        }
    }
}


.quotation-offer-popup {
    .un-modal-body-scroll {
        @apply min-h-full;
        > .un-scroll > .simplebar-wrapper > .simplebar-mask > .simplebar-offset > .simplebar-content-wrapper > .simplebar-content {
            @apply flex flex-col min-h-full;
        }
    }

    .insurance-search {
        @apply flex-1 mx-4 mt-0 w-auto max-w-none;
    }

    .insurance-search-item {
        > ._container > ._footer > ._button {
            > .un-button {
                &.-btn-add {
                    @apply w-12;
                }
            }
        }
    }
}


.chat-order-item {
    @apply border rounded-md p-2 bg-white hover:ring-4 hover:ring-opacity-25 hover:ring-blue-500 hover:border-blue-500;

    + .chat-order-item {
        @apply mt-4;
    }

    > ._top {
        @apply flex items-center;
        > ._status-bar {
            @apply flex-1 bg-grey-blue-600 rounded text-center text-white;
            &.-orange {
                @apply bg-orange-600;
            }

            &.-red {
                @apply bg-red-600;
            }

            &.-green {
                @apply bg-green-600;
            }

            &.-blue {
                @apply bg-blue-600;
            }
        }

        > ._btn-edit {
            @apply ml-2 py-0 px-2 leading-none min-h-0;
        }
    }

    > ._footer {
        @apply flex mt-2 pt-2 border-t border-dashed;
    }


    > .detail-item {
        @apply border border-blue-600 mb-0;
        + .detail-item {
            @apply mt-0 border-t-0;
        }

        > ._label {
            @apply pr-2 bg-blue-100 text-blue-800;
        }

        > ._value {
            @apply pl-2;
            word-break: break-all;
        }
    }

    > ._detail-header {
        @apply mt-3 font-bold;
        + .detail-item {
            @apply mt-0;
        }
    }

    > ._car {
        @apply text-xl;
        > ._gen {
            @apply text-blue-500;
        }
    }


    > ._detail {
        @apply my-1;
    }

    > ._note {
        @apply text-sm text-grey-600;
    }

    > ._offers {
        @apply border rounded bg-white mt-2;
    }
}

.chat-order-item-photos {
    @apply flex flex-wrap -mx-1;
    > ._item {
        @apply w-12 h-12 m-1 rounded bg-cover bg-center;
        &:hover {
            @apply ring-2 ring-blue-500 ring-opacity-25
        }
    }
}

.chat-order-offer {
    @apply flex items-start p-2 border rounded cursor-pointer;

    &:hover {
        @apply bg-orange-50;
    }

    + .chat-order-offer {
        @apply mt-1;
    }

    > ._logo {
        @apply rounded-xl bg-cover bg-center bg-no-repeat bg-grey-blue-200 w-12 h-12 mr-2;
    }

    > ._detail {
        @apply flex-1;
        > ._header {
            @apply flex flex-wrap items-center leading-none mb-1;
            > ._broker {
                @apply text-sm leading-none ml-2 text-orange-700;
            }

            > ._paylot {
                @apply text-sm leading-none ml-auto text-red-500;
            }
        }

        > ._money {
            @apply flex items-center;

            > ._item {
                @apply flex items-end;
                > ._label {
                    @apply text-grey-blue-500 text-sm;
                }

                > ._value {
                    @apply ml-2;
                }
            }

            > ._btn-select {
                @apply ml-2 text-green-500 hover:text-red-500;
            }
        }
    }
}

.chat-order-document {
    @apply items-start p-2 border rounded;
    + .chat-order-document {
        @apply mt-1;
    }

    > ._header {
        @apply flex flex-wrap items-center;
        > ._type {
            @apply text-xl font-bold text-blue-700;
        }

        > ._company {
            @apply ml-auto;
        }
    }

    > ._date {
        @apply text-grey-500 text-center;
    }
}

.chat-cusfollow-item {
    @apply flex border rounded bg-white p-2 cursor-pointer hover:bg-grey-blue-50;
    + .chat-cusfollow-item {
        @apply mt-3;
    }

    > .un-icon {
        @apply flex items-center text-2xl text-grey-500 mx-2;
    }

    > ._detail {
        @apply flex-1;
        > ._title {
        }

        > ._detail {
            @apply text-xs truncate text-grey-600;
        }

        > ._tel {
            @apply text-xs truncate text-blue-600;
        }

        > ._date {
            @apply text-right text-red-500 text-sm;
        }
    }
}

.chat-follow-item {
    @apply flex items-start mt-1 cursor-pointer hover:bg-orange-50;
    + .chat-follow-item {
        @apply mt-2;
    }

    > ._icon {
        @apply mx-2 text-red-500;
    }

    > ._detail {
        @apply flex-1 ml-2;

        > ._head {
            @apply flex items-start;
            > ._state {
                @apply flex-1;
            }

            > ._due {
                @apply bg-blue-700 rounded-md px-2 py-1 leading-none text-sm text-white;
            }
        }

        > ._reason {
            @apply text-green-600;
        }

        > ._note {
            @apply text-sm text-grey-600;
        }
    }
}
