$sideWidth: 250px;
$appbarMargin: 24px;

.layout {
    @apply flex flex-row flex-1 h-screen;
    > div:not([class]) {
        @apply flex flex-row flex-1 h-screen;
    }
}

.layout-sidemenu {
    @apply flex flex-col w-60;
    &.-grow {
        @apply flex-grow;
    }

    &.-mini {
        @apply w-14;
        .layout-mainmenu {
            @apply p-2 justify-center;

            &:hover {
                @apply relative;

                > ._container {
                    @apply flex absolute shadow top-0 left-0 py-2 pr-2 z-40 w-72;
                    .un-icon {
                        @apply inline-block;

                        &.-menu {
                            @apply mr-2;
                        }
                    }
                }

                + .layout-menuifo {
                    @apply block relative;
                    > ._container {
                        @apply absolute z-40 w-72;
                    }
                }
            }

            .un-icon {
                @apply hidden;

                &.-menu {
                    @apply block w-14 text-center leading-8;
                }
            }
        }

        .layout-menuifo {
            > ._container {
                @apply text-white rounded-br-lg;
            }
        }

        ._no-mini-show, .layout-menuifo, .layout-sysmenu {
            @apply hidden;
        }

        .layout-menu {
            @apply overflow-visible;
            ._menu {
                @apply block relative py-2 px-0 text-center z-30;
                &:hover {
                    > span {
                        @apply flex;
                    }
                }

                > .un-icon {
                    @apply m-0;
                }

                > span {
                    @apply absolute inset-y-0 px-2 hidden items-center shadow-lg;
                    left: 100%;
                    width: 200px;
                }
            }
        }


        .layout-option {
            @apply block p-0;
            > ._icon {
                @apply hidden;
            }

            .btn-notify {
                @apply mx-auto;
            }
        }
    }
}

.layout-mainmenu {
    @apply flex relative h-12 px-2 items-center;
    ._container {
        @apply flex flex-1;
    }

    .un-icon {
        @apply w-8 h-8 text-white text-center leading-8;
        font-size: 1.25rem;

        &.-menu {
            display: none;
        }
    }
}

.layout-menuifo {
    > ._container {
        @apply flex px-2 py-0.5 text-xs;
        > ._user {
            margin-left: auto;
        }
    }
}

.layout-menu {
    @apply relative flex flex-col flex-grow pt-2;

    ._menu {
        @apply flex justify-start items-center px-4 py-2 rounded-none;
        .un-icon {
            @apply w-10 text-center text-2xl mr-2;
        }

        ._text {
            @apply text-lg;
        }
    }

    > .btn-coll {
        padding: .5rem;
        text-align: center;
    }
}

.layout-option {
    @apply flex items-center pr-2 mt-2;
    > ._icon {
        @apply flex flex-1 p-2 mx-2 rounded-full bg-white bg-opacity-50;
    }

    .btn-notify {
        @apply relative flex items-center justify-center h-12 w-12;
        ._alert {
            @apply absolute top-2 right-2;
        }

        .un-icon {
            @apply text-3xl h-12 w-12;
        }
    }
}

.layout-section-menu {
    @apply flex px-4 -m-4 mb-4;
    > ._item {
        @apply leading-6 py-2.5 px-4 cursor-pointer;
        > .un-icon {
            @apply text-xl w-8;
        }
    }

    > ._info {
        @apply ml-auto flex self-center;
    }
}

.layout-content {
    @apply p-4 flex-1 flex flex-col overflow-auto;
}


.paging-container {
    > .paging {
        > ._btn {
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }
}


.notify-popup {
    @apply flex flex-col w-72 h-96 p-1 ml-1 mb-2 border-none rounded-lg shadow-2xl z-50;
    left: 100%;
}

.notify-list {
    @apply bg-white;
}

.notify-empty {
    @apply flex items-center justify-center flex-1 text-grey-blue-200 text-2xl;
}

.notify-item {
    @apply bg-grey-blue-50 rounded-md p-2 m-1 cursor-pointer hover:bg-grey-blue-100;

    &:hover {
        > ._text {
            @apply text-grey-900;
        }
    }

    > ._text {
        @apply text-grey-700;
    }

    > ._time {
        @apply mt-1 text-right text-blue-500 text-xs;
    }
}


.sumbox {
    @apply relative border rounded py-2 px-4;
    width: 220px;

    &.-sm {
        width: 120px;
    }

    > ._icon-edit {
        @apply absolute right-1 top-1;
    }

    > ._label-flex {
        @apply flex;
    }

    > ._value {
        @apply mt-2 block text-right text-3xl;
    }
}

.sumbox-fm {
    > .un-icon {
        font-size: 32px;
        line-height: 78px;
    }
}


.ss-crosshairs {
    @apply absolute border border-blue-500 rounded bg-blue-500 bg-opacity-50;
    z-index: 5000;
}

.ss-overlay {
    @apply fixed inset-0 bg-black bg-opacity-50;
    z-index: 4500;

    &.-start {
        @apply bg-transparent;
    }
}

@include desktop-down() {
    .layout-section-menu {
        ._item {
            .un-icon {
                margin: 0;
                text-align: center;
            }

            ._title {
                display: none;
            }
        }
    }

    ._info {
        .-nomin {
            display: none;
        }
    }
}
