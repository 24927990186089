// v.0.6.64

.un-page-title > ._extend {
    .un-form-item {
        + .un-form-item {
            @apply mt-0 ml-4;
        }

        > ._label {
            @apply w-auto;
        }

        > ._content > ._control > .un-inputbox {
            min-width: 150px;
        }
    }
}

.un-form-item-sm .un-form-item > ._label,
.un-form-container > .un-form-body > .un-form-item > ._label {
    @apply w-32;
}

.un-form-header:not(.-vertical) {
    max-width: 20%;

    .un-form-label {
        @apply text-left;
    }
}


.un-input-upload-item {
    > ._name {
        @apply whitespace-normal;
    }
}
