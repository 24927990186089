.handsontable {
    @each $color in $color-names {
        .-#{$color} {
            @apply bg-#{$color}-50;
            &.-header {
                @apply bg-#{$color}-300;
            }

            &.-header2 {
                @apply bg-#{$color}-200;
            }

            &.-up {
                @apply bg-#{$color}-200;
            }

            &.-down {
                @apply bg-#{$color}-100;
            }
        }
        .-#{$color}-text {
            @apply text-#{$color}-500 #{!important};
        }
    }

    .-sum {
        @each $color in $color-names {
            .-#{$color} {
                @apply bg-#{$color}-200;
            }
        }
    }

    .-edited {
        background-color: wcolor($red, 25%) !important;
        border-bottom: 1px solid $red;
    }

    .-selected {
        color: $blue !important;
    }

    input[type=checkbox]:checked {
        filter: invert(100%) hue-rotate(18deg) brightness(2);
    }

    .l {
        text-align: left !important;
    }

    .c {
        text-align: center !important;
    }

    .r {
        text-align: right !important;
    }
}

.report-table {
    thead {
        tr {
            th, th > div, th > .relative {
                padding: 0;
                height: .375rem !important;

                > .colHeader {
                    font-size: .5rem;
                }
            }
        }
    }

    tbody {
        tr {
            th, th > div, th > .relative {
                padding: 0;
                width: .375rem !important;
            }
        }
    }

    td {
        &.-header {
            vertical-align: bottom;
            text-align: center !important;
        }

        &.-header2 {
            @apply font-bold;
        }

        &.-group {
            text-align: left !important;
            font-weight: bold;
        }

        &.-fontBold {
            font-weight: bold;
        }

        &.-light_yellow {
            background-color: wcolor($yellow, 5%);
        }

        &.-light_blue {
            background-color: wcolor($blue, 5%);
        }

        &.-light_grey {
            background-color: wcolor($grey, 5%);
        }

        &.-light_purple {
            background-color: wcolor($purple, 5%);
        }
    }
}

.form-datatable {
    > ._header {
        @apply flex p-2 items-center;
        min-height: 40px;

        &.-sm {
            > ._title {
                font-size: 12pt;
            }
        }

        > ._title {
            font-size: 14pt;
            color: $grey-blue;
        }
    }

    > .datatable-container {
        border: 1px solid wcolor($grey, 25%);
        border-radius: .125rem;
        background-color: wcolor($grey-blue, 10%);

        > ._protection {
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
        }
    }
}
