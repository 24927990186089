.pay-header {
    @apply flex items-center rounded py-2 px-4 mb-8 text-white;
    background: $mainColor;

    > ._customer {
        flex-grow: 1;

        ._name {
            font-size: 18pt;

            > ._tel {
                margin-left: 1rem;
                font-size: 1rem;
            }
        }
    }

    > ._lot {
        padding: 0 1rem;
        text-align: center;

        > ._label {
            font-size: 10pt;
        }

        > ._value {
            font-size: 20pt;
        }
    }
}
