.flex-break-hidden {
    @apply hidden h-0;
    flex-basis: 100%;
}

.mock {
    @apply border rounded-md text-4xl mb-8 text-center flex items-center justify-center h-96 text-grey-500 bg-grey-50;
    &.-sm {
        @apply h-auto py-12 mb-0 text-2xl;
    }
}

.mock-page {
    @apply flex flex-1 items-center justify-center text-9xl text-grey-200 font-mono;
}

.un-list-body > .mock, .un-list-container > .mock {
    @apply h-auto py-8 m-2;
}

.un-list-body .mock {
    @apply flex-1 m-3 py-8;
}

.ul-note {
    @apply mb-0 list-disc pl-8;
    li {
        @apply text-grey-blue-500 text-sm;
    }
}

.un-form-item-sm {
    .un-form-item {
        > ._label {
            @apply w-40;
        }
    }
}

.un-form-item {
    ._label-inside {
        @apply text-grey-500 text-right px-4;
    }
}

.system-info-form {
    @apply p-3 border border-grey-300 rounded-md shadow-inner;
    background-color: #fafafa;

    ._header {
        @apply text-lg text-grey-blue-500 mb-2;
    }

    .un-list-container {
        @apply bg-white;
    }
}


.info-box {
    @apply bg-grey-blue-50 p-1 rounded-md;
}

.info-detail {
    @apply p-4 flex items-center text-grey-500 border rounded;
    + .info-detail {
        @apply mt-4;
    }

    @each $color in $color-names {
        &.-#{$color} {
            @apply border-#{$color}-400 bg-#{$color}-50 text-#{$color}-500;
            > .un-icon {
                color: text-#{$color}-500;
            }
        }
    }

    > .un-icon {
        font-size: 1.5rem;
        width: 2rem;
        text-align: center;
        margin-right: .75rem;
    }

    > ._value {
        @apply text-grey-900 font-bold ml-auto;
    }

    > ._unit {
        margin-left: 1rem;
    }
}


.detail-item {
    @apply flex my-2;

    > ._label {
        @apply w-32 text-right pr-4 text-grey-blue-500 truncate;
        direction: rtl;

        &.-xs {
            @apply w-12;
        }

        &.-sm {
            @apply w-20;
        }
    }

    > ._value {
        flex: 1;
    }

    ol {
        margin-bottom: 0;
    }
}

.detail-item-line {
    @apply border-t border-grey-blue-100 border-dashed mb-4 mt-3;
}

.detail-item-photos {
    @apply flex flex-wrap;
}

.detail-item-photo {
    @apply w-24 m-1;

    &:hover {
        > ._img {
            @apply border-orange-500;
        }

        > ._title {
            @apply text-orange-500;
        }
    }

    > ._img {
        @apply h-24 border rounded bg-center bg-cover cursor-pointer;
    }

    > .un-icon {
        @apply flex justify-center items-center w-full h-24 border border-red-200 text-red-500 rounded;
    }

    > ._title {
        @apply text-center text-sm text-grey-500 leading-none mt-2;
    }
}

.detail-table {
    border: 1px solid wcolor($grey, 50%);
    border-radius: .25rem;
    padding: 1px;

    .table {
        td {
            padding: .5rem;
            font-size: 10.5pt;
        }

        .width-50 {
            width: 50px;
        }

        .width-100 {
            width: 100px;
        }

        .width-date {
            width: 100px;
        }

        .width-money {
            width: 90px;
        }
    }
}


.button.btn-icon {
    min-width: 0;
}

.button.btn-disabled {
    opacity: .3;
    border-color: #9a9da0;
    color: #343a40;
    cursor: not-allowed;
}


.btn-cell {
    height: 22px;
    font-size: 9pt !important;
    padding: 0 !important;
    width: 100% !important;
    display: block !important;
    min-width: 0 !important;

    > .MuiButton-label {
        line-height: 22px;
    }
}

.btn-delete {
    padding-left: 0;
    padding-right: 0;
    min-width: 0 !important;
    margin-right: auto !important;
}

.btn-save {
    min-width: 120px !important;
}


.dialog-icon {
    @apply block text-9xl text-center mt-8 mb-12;
}

.dialog-link {
    @apply p-2 border rounded-lg mb-4 bg-white;

    > ._row {
        @apply flex items-center;
        + ._row {
            @apply mt-4;
        }

        > .un-button {
            @apply px-0 w-20;

            &:first-child {
                @apply flex-grow w-auto;
            }

            & + .un-button {
                @apply ml-2;
            }
        }
    }
}


.history-item {
    &:last-child {
        margin-bottom: 1rem !important;
    }

    .MuiAccordionSummary-root {
        background-color: wcolor($grey-blue, 15%);
        min-height: 40px !important;
        padding-left: .5rem;
    }

    .MuiAccordionSummary-content {
        margin: 0 !important;

        line-height: 22px;
        font-size: 10pt;
    }

    ._type {
        background-color: $black;
        color: $white;
        padding: 0 .5rem;
        font-size: 10pt;
        border-radius: .25rem;
        margin-right: .5rem;
    }


    ._by {
        margin-left: auto;
        margin-right: .5rem;
        font-weight: bold;
    }

    ._time {
        color: $grey-blue
    }

    .MuiAccordionDetails-root {
        padding: .5rem;
        max-height: 400px;
        overflow: auto;

        pre {
            flex: 1;
            margin: 0;
        }
    }
}

.history-item-type {
    background-color: $black;
    color: $white;
    padding: 0 .5rem;
    font-size: 10pt;
    border-radius: .25rem;
    margin-right: .5rem;
}


.form-modal_size.-sm .history-item {
    ._url, ._formnow {
        display: none;
    }
}


.list-option {
    display: flex;
    margin: 0;
    padding: 0 .5rem .5rem .5rem;

    > .input-checkbox {
        margin-left: .5rem;

        > ._check-container {
            padding: 0;

            .form-check .form-check-label {
                font-size: 10.5pt;
            }
        }
    }
}

.list-note {
    font-size: 8pt;
    padding-left: 1.25rem;
    margin-bottom: 0;
    color: $grey;

    ul {
        padding-left: 1.25rem;
        margin-bottom: 0;
    }
}

.title-dropdown {
    @apply px-3 py-1 cursor-pointer rounded hover:ring-2 hover:ring-orange-500 hover:ring-opacity-50 hover:bg-orange-50;
}

.report-title-selector {
    @apply p-2;
    width: 600px;

    ._type {
        @apply text-sm text-grey-blue-500 py-1;
    }

    ._items {
        @apply flex flex-wrap;
        &.-h-10 ._item {
            @apply w-1/5;
        }

        &.-h-20 ._item {
            @apply w-1/3;
        }

        &.-h-30 ._item {
            @apply w-1/2;
        }

        ._item {
            @apply text-base px-3 py-1 rounded truncate cursor-pointer hover:bg-blue-50 hover:ring-2 hover:ring-blue-500 hover:ring-opacity-50;
            &.-active {
                @apply bg-green-500 text-white;
            }
        }
    }
}

.text-bank {
    @apply align-bottom;
}

.icon-bank {
    @apply inline-block w-5 h-5 my-auto rounded p-px text-center;
    > img {
        @apply max-h-full max-w-full;
    }
}

.icon-merge {
    @apply mx-2 text-5xl;
}


.mentions {
    @apply border rounded px-4 py-2 mb-2;
    input:focus {
        @apply outline-0;
    }
}

.mentions__control {
    @apply relative;
}

.mention-user-name {
    @apply bg-blue-100 rounded;
}

.mentions__suggestions {
    top: auto !important;
    bottom: 100% !important;
}

.mentions__suggestions__list {
    @apply border rounded bg-white;
}

.mentions__suggestions__item {
    @apply m-px py-1 px-3 text-sm rounded;
}

.mentions__suggestions__item--focused {
    @apply bg-grey-blue-50;
}


.un-tag.-value {
    @apply inline-flex pr-px py-px h-auto;

    &.-red {
        > ._v {
            @apply text-red-500;
        }
    }

    &.-green {
        > ._v {
            @apply text-green-500;
        }
    }

    > ._n {
        @apply pr-2;
    }

    > ._v {
        @apply flex-1 bg-white px-1 rounded-r-sm;
    }
}


.un-input-search-row._no-wrap {
    .un-input-radio {
        @apply flex-nowrap;
    }
}

.text-box {
    @apply whitespace-pre-wrap;
}


.-total_cell-left.-total_cell {
    text-align: left !important;
}