.tab-container {
    background-color: wcolor($mainColor, 15%);
}

.tab-rim {
    margin-left: -1rem;
    margin-right: -1rem;
    &.-rim-top {
        margin-top: -1rem;
    }
}