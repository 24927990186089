.group-roles {
    @apply border rounded;
    > .un-form-container {
        @apply p-2;
        .un-form-body {
            @apply mt-0 py-2 pr-6;
        }
    }

    ul {
        @apply mb-0 pl-8;
    }
}

.group-role-item {
    @apply flex m-1 py-1 px-2 rounded text-grey-500 cursor-pointer;
    &:hover {
        @apply bg-orange-100 text-orange-500;
        > ._icon:before {
            content: "\f055";
        }
    }

    &.-active {
        @apply bg-green-100 text-green-500;
        &:hover {
            @apply bg-red-100 text-red-500;
            > ._icon {
                font-weight: 300;

                &:before {
                    content: "\f057";
                }
            }
        }

        > ._icon {
            font-weight: 900;

            &:before {
                content: "\f058";
            }
        }
    }

    ._icon {
        @apply flex items-center justify-center w-6 h-6 mr-1;
    }

    ._key {
        @apply ml-auto pl-4;
    }
}


.qr-test {
    @apply border rounded p-4 mt-6 w-96;

    > ._code {
        @apply text-grey-200 text-center my-4;
        overflow-wrap: break-word;
        font-size: 3pt;
    }

    > ._money {
        @apply text-3xl text-center text-blue-600;
    }

    > ._expire {
        @apply text-center text-red-600;
    }
}

.qr-placeholder {
    @apply flex h-96 border items-center justify-center;
    > .un-icon {
        @apply text-9xl text-grey-blue-500;
    }
}

.qr-placeholder-img {
    @apply rounded-lg;
}

.text-code {
    overflow-wrap: break-word;
    overflow: auto;
}

.w-code {
    width: 700px;
}

.chat-log-files {
    @apply flex mt-2;

    > a {
        @apply block w-12 h-12 bg-center bg-cover border rounded hover:border-red-500;
        + a {
            @apply ml-2;
        }
    }
}


.admin-chat-ai-form {
    @apply mt-6;
    > ._input {
        @apply flex items-center;
    }
}

.admin-server-munin {
    @apply grid grid-cols-5 gap-2 mb-4;
    > ._item {
        img {
            @apply border rounded p-0.5 w-full object-cover object-top;
            max-height: 250px;
            height: 120px;
        }
    }
}


.admin-tasks {
    @apply flex flex-col ml-4;
    width: 400px;

    > ._head {
        @apply text-center text-lg text-grey-blue-500 mb-2;
    }

    ._item {
        @apply border rounded py-1 pl-2 pr-1 text-sm flex cursor-pointer hover:border-orange-500;
        + ._item {
            @apply mt-2;
        }

        &.-active {
            @apply ring-2 ring-blue-200;
        }

        @each $color in $color-names {
            &.-#{$color} {
                @apply border-#{$color}-500 bg-#{$color}-50;
                > ._name {
                    @apply text-#{$color}-700;
                }
            }
        }

        > ._name {
            @apply flex-1;
        }

        > ._from {
            @apply mr-2 text-blue-500;
        }

        > ._time {
            @apply text-grey-blue-500;
            + ._from {
                @apply ml-2 mr-0;
            }
        }

        > ._cost {
            @apply w-10 ml-2 bg-white text-right text-red-500 text-xs py-0.5 px-1 rounded overflow-hidden;
        }
    }
}

.admin-task-item {
    ._item {
        @apply border rounded py-1 px-2 mt-2 text-sm flex cursor-pointer hover:ring-2 hover:ring-orange-200;

        &.-active {
            @apply ring-4 ring-blue-200;
        }

        @each $color in $color-names {
            &.-#{$color} {
                @apply border-#{$color}-500 bg-#{$color}-50;
                > ._name {
                    @apply text-#{$color}-700;
                }
            }
        }

        > ._time {
            @apply text-grey-blue-500;
            + ._from {
                @apply ml-2 mr-0;
            }
        }

        > ._cost {
            @apply ml-auto bg-red-50 text-red-500 text-xs py-0.5 px-1 rounded;
        }
    }
}

.admin-task-log {
    overflow: auto;
    max-height: 500px;
}

.admin-task {
    @apply relative flex flex-1 flex-col border p-px;

    > ._item-header {
        @apply flex text-sm h-5 text-grey-blue-300;
        > ._name {
            @apply text-center w-32;
        }

        > ._times {
            @apply flex flex-1 ml-px;
            > ._h {
                @apply flex-1;
                > ._text {
                    @apply pl-2;
                }

                > ._line {
                    @apply absolute inset-y-0 border-l border-dashed;
                }
            }
        }

        > ._last {
            @apply w-14 text-center border-l border-dashed;
        }
    }

    > ._item-list {
        ._item {
            @apply flex hover:bg-orange-100;
            > ._name {
                @apply truncate text-xs leading-6 px-1 w-32 text-grey-blue-500 border-t border-dotted border-grey-blue-200;
            }

            > ._times {
                @apply flex-1 relative border-t ml-px;
                > ._time {
                    @apply absolute top-1 bottom-1 bg-green-500 opacity-50 cursor-pointer;
                    min-width: 4px;

                    &:hover {
                        opacity: 1;
                    }

                    &.-slow1 {
                        @apply bg-orange-500;
                    }

                    &.-slow2 {
                        @apply bg-red-400;
                    }

                    &.-slow3 {
                        @apply bg-red-500;
                    }

                    &.-slow4 {
                        @apply bg-red-600;
                    }

                    &.-past {
                        @apply bg-blue-500;
                    }
                }
            }

            > ._last {
                @apply text-grey-blue-500 text-center text-sm leading-6 w-14 border-t border-l;
            }
        }
    }
}

.admin-task-view-detail {
    @apply relative w-full mt-1 mb-3 rounded p-2 bg-grey-800 text-white h-auto overflow-y-hidden whitespace-pre-wrap;
    max-height: 100px;
    outline: none;

    &.-exp {
        max-height: none;
    }
}


.admin-ticket {
    @apply w-fill flex flex-1;


    .ticket-box {
        @apply w-1/3 border flex flex-col flex-1;

        + .ticket-box {
            @apply ml-3;
        }

        .ticket-title {
            @apply mt-2 text-center;
        }
    }


    .ticket-item {
        @apply h-28 m-2 p-2 border-2 rounded-lg hover:border-blue-400 cursor-pointer overflow-y-hidden
    }


}