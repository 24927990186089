.chat-container {
    @apply flex flex-1 -m-4;
    background-color: #f5f8fa;

    > ._room {
        @apply relative flex flex-col border-r;
        width: 400px;

        &.-mini {
            width: 100px;

            > ._summary {
                @apply hidden;
            }

            .chat-user-online {
                ._user-items, .un-button {
                    @apply hidden;
                }
            }

            .chat-room {
                ._detail ._title ._time {
                    @apply hidden;
                }
            }
        }

        > ._control {
            @apply flex bg-white items-center;
            > .un-inputbox {
                @apply my-0 border-0 flex-1;
            }

            > ._option {
                @apply mt-2.5 flex;

                .un-dropdown {
                    @apply ml-auto;
                }

                .un-button {
                    @apply h-6 w-28 p-0.5 text-sm leading-none;
                }
            }
        }

        > ._summary {
            @apply px-2 py-0.5 border-b bg-main-50 border-main-200 text-xs flex;
            > span > .un-icon {
                @apply mr-1 text-sm;
            }

            ._check-all {
                @apply text-grey-500 cursor-pointer hover:text-red-500;
                &.-check {
                    @apply font-bold text-blue-500 hover:text-blue-600;
                }
            }
        }

        > ._room-list-container {
            @apply relative flex flex-1;
        }

        > .un-input-search {
            @apply bg-white flex-grow-0 mb-2;
        }
    }

    > .chat-room-info {
        @apply w-96 border-l;
    }

    > .chat-room-box {
        @apply flex flex-1 flex-col;

        > ._message-container {
            @apply relative flex-1;
            > ._message {
                @apply absolute inset-0 overflow-y-auto flex flex-col-reverse p-2;
            }
        }

        > ._form {
            @apply p-2 border-green-200 border-dashed;
            border-top-width: 1px;

            > ._attach {
                @apply mt-2 flex items-start;
                > ._item {
                    @apply ml-2 bg-white;
                    &.-img {
                        @apply flex h-16 w-16 text-xs rounded shadow bg-cover bg-center;
                    }

                    &.-file {
                        @apply border rounded shadow text-sm pl-4;
                    }

                    > .un-icon {
                        @apply ml-auto text-red-500;
                    }
                }
            }

            textarea {
                min-height: 60px;
            }
        }
    }
}

.chat-user-online {
    @apply p-2 border-t;

    &.-online {
        @apply bg-green-500;
        > ._control > ._info, > ._control > .un-icon {
            @apply text-white;
        }
    }

    > ._control {
        @apply flex items-center;

        > ._btn-bc {
            @apply mr-4;
            ._text {
                @apply px-0;
            }
        }

        > ._info {
            @apply text-sm text-red-500 text-center mr-auto cursor-pointer hover:text-red-500;
        }

        > .un-icon {
            @apply -m-1;
        }
    }

    > ._user-items {
        @apply flex px-5 flex-row-reverse justify-end;
        > ._user-item {
            @apply m-1 -ml-5 w-10 h-10 bg-grey-blue-500 rounded-full text-white flex items-center justify-center uppercase cursor-pointer border-2 border-grey-blue-600 hover:border-blue-400 hover:bg-blue-600 hover:ring-2 hover:ring-blue-200;
            &[style] {
                @apply bg-center bg-cover bg-white border-grey-blue-100 hover:border-blue-500 ;
            }
        }
    }

}

.chat-room-provider {
    @apply bg-black rounded text-white;
    &.-ln {
        background-color: #00c300;
    }

    &.-fb {
        background-color: #1877f2;
    }
}

.chat-room {
    @apply relative flex p-3 border-b items-center cursor-pointer bg-transparent;

    &:hover {
        @apply bg-green-200 border-green-600 border-t -mt-px;
        > ._avatar {
            @apply text-white;
        }
    }

    &.-active {
        @apply bg-blue-100;
        > ._detail > ._sub > ._last {
            @apply text-blue-500;
        }
    }

    &.-selected {
        @apply bg-green-100 border-b-4 border-green-400;
    }

    > ._profile {
        @apply mr-3 mb-auto h-12 w-12 rounded-full bg-cover bg-center;
    }

    > ._avatar {
        @apply flex items-center justify-center mr-3 mb-auto h-12 w-12 text-green-200 opacity-80 text-6xl;
    }

    > ._detail {
        @apply flex-1 truncate;
        > ._title {
            @apply relative flex items-center;

            > ._hide {
                @apply bg-grey-blue-400 text-sm text-white rounded-full px-2 mr-1;
            }

            > ._name {
                @apply flex-1 text-lg truncate leading-none;
                &.-customer {
                    @apply text-orange-700;
                }
            }

            > ._time {
                @apply text-sm text-blue-400;
            }

            > ._alert {
                @apply relative mr-2 -mt-0.5;
            }
        }

        > ._last {
            @apply text-sm text-grey-500 truncate mt-1;
        }

        > ._last-office {
            @apply text-sm border border-orange-500 bg-orange-50 py-1 px-2 text-orange-600 truncate mt-1 mb-2;
        }

        > ._sub {
            @apply flex mt-1 text-sm leading-tight items-center;
            min-height: 1.25rem;

            > ._state {
                @apply text-blue-500 mr-auto;
            }

            > ._emp {
                @apply text-red-500;
            }

            > ._customer {
                @apply text-orange-500 ml-2;
            }
        }
    }

    > .chat-room-provider {
        @apply absolute left-2 top-2 rounded w-5 h-5 text-xs flex items-center justify-center;
    }
}

.chat-room-profile {
    @apply relative flex items-center py-1 pl-4 pr-1 border-b border-main-400 bg-main-100 overflow-hidden;

    > .chat-room-provider {
        @apply flex items-center justify-center absolute left-2 top-2 rounded w-6 h-6 text-center cursor-pointer hover:opacity-80;
    }

    > ._profile {
        @apply w-14 h-14 rounded-full bg-cover bg-center mb-auto mr-2 my-auto;
    }

    > ._option {
        @apply w-8 ml-2;
    }

    > ._name {
        @apply flex-1;
        > ._text {
            @apply py-1 px-2 text-xl text-center;
            &.-customer {
                @apply text-orange-700

            }
        }

        > ._note {
            @apply px-2 text-sm text-center text-grey-blue-500 rounded-lg hover:bg-blue-50 focus:outline-none;

            &:empty:not(:focus):before {
                color: grey;
                content: "note..."
            }
        }
    }
}

.chat-room-info {
    @apply relative flex flex-col overflow-x-hidden;

    > ._menu {
        @apply flex px-0 border-none;
        > .un-menubar-item {
            @apply flex-1 flex flex-col items-center py-3 bg-main-50 text-main-700;

            &:before {
                @apply bg-main-800;
            }

            &.-active {
                @apply bg-main-500 text-white;
            }

            > .un-icon {
                @apply m-0 text-center text-xl;
            }

            > ._label {
                @apply text-sm;
            }
        }
    }

    .un-form-container {
        + .un-form-container {
            @apply border-t border-main-200;
        }

        &:first-child {
            @apply pt-4;
        }

        &.-collapse {
            > .un-form-header {
                @apply text-main-500;
            }

            &.-collapse-close {
                > .un-form-header {
                    @apply mb-0;
                    > .un-form-label {
                        @apply w-full;
                    }
                }
            }
        }

        > .un-form-header {
            @apply pl-2 pb-0 m-0;
            > ._text {
                @apply mt-0;
            }
        }

        > .un-form-body {
            @apply px-3 py-4 mt-0;
        }
    }

    ._form {
        @apply mr-0.5 flex items-center;
        + ._form {
            @apply mt-4;
        }

        > .un-icon {
            @apply w-12 text-2xl text-center;
        }

        > .un-inputbox, > .un-button {
            @apply mb-0;
        }

        > .un-inputbox {
            @apply flex-1;
        }
    }

    /*
    ._state {
        @apply relative mt-3 pl-4;

        &:before {
            @apply absolute z-0 w-2 inset-y-0 left-6 bg-blue-800 bg-grey-200 rounded-md;
            content: " ";
            display: block;
        }

        ._controls {
            @apply relative z-10;
        }

        ._icon {
            @apply bg-white;
        }

        .un-input-radio-item {
            @apply flex-wrap;
            &.-checked {
                ._label {
                    @apply text-blue-500 font-bold;
                }
            }

            ._history {
                @apply ml-8 mt-1 text-sm text-grey-blue-500 w-full;
            }
        }
    }
    */

    > ._field {
        @apply relative border rounded-md mx-0.5 flex-1 pb-2 mt-3 overflow-y-auto bg-white;
        > ._content {
            @apply absolute inset-3 top-6;
            .un-input-checkbox, .un-input-radio {
                @apply mb-6 mt-6;
            }

            .un-inputbox {
                @apply w-full;
            }
        }
    }

    .customer-view-box {
        @apply rounded-lg mt-3 mx-0.5;
        > .un-button {
            @apply absolute bottom-1 right-1 left-auto;
        }

        > .row {
            @apply flex-col m-0;
            > .col {
                @apply p-0;
                width: 100% !important;
            }
        }

        .detail-item {
            @apply text-xs;
            ._label {
                @apply w-20;
            }
        }
    }

    .un-list-container {
        @apply bg-white;
    }
}

.chat-room-info-detail {
    @apply m-2 border rounded-md bg-white;
    .detail-item {
        @apply my-1 text-sm;
        a {
            @apply hover:text-red-500;
        }
    }
}

.chat-room-info-comment {
    @apply block hover:bg-orange-50;
    > ._info {
        @apply flex items-end text-xs;
        > ._user {
            @apply text-grey-blue-400 mr-2;
        }

        > ._time {
            @apply text-blue-400;
        }

        .un-icon {
            @apply ml-auto -my-1;
        }
    }
}

.chat-room-info-comment-input {
    @apply my-1;
    .un-button {
        @apply py-4 px-2;
    }

    textarea {
        @apply resize-none;
        max-height: 64px;
    }
}

.chat-option {
    @apply flex-1 p-8;
    > ._selected {
        @apply flex items-center text-3xl text-grey-500;
        ._value {
            @apply text-red-500;
        }

        > .un-icon {
            @apply text-4xl mr-4;
        }
    }

    > ._control {
        @apply flex flex-col ml-12 mt-8 max-w-md;
    }
}


.chat-suggestion-view {
    @apply whitespace-pre-wrap text-sm bg-grey-blue-50 py-1 px-2 rounded-md;
}

.chat-suggestion-message {
    @apply flex items-start;
    + .chat-suggestion-view {
        @apply mt-2;
    }

    > ._time {
        @apply w-10 text-grey-600 mr-2 text-center;
    }

    > ._me {
        @apply font-bold w-8 text-right mr-2;
    }

    > ._text {
        @apply flex-1;
    }
}

.chat-message-box {
    @apply flex flex-1 flex-col bg-main-50;

    > ._message-container {
        @apply relative flex-1;
        > ._message {
            @apply absolute inset-0 overflow-y-auto flex flex-col-reverse p-2;

            > ._message-typing {
                @apply flex mt-4 mb-2 text-grey-500;
                > .un-alert-dot {
                    @apply my-auto mx-2;
                    > ._ping {
                        @apply bg-grey-300;
                    }

                    > ._dot {
                        @apply bg-grey-400;
                    }
                }
            }
        }
    }

    > ._form-option {
        @apply relative;
        &.-office {
            > ._mode {
                @apply border-orange-500;
                > .un-menutab-item {
                    &:hover {
                        > ._label {
                            @apply text-orange-600;
                        }
                    }

                    &.-active {
                        @apply border-orange-500 border-b-0 bg-orange-50;
                        &:before {
                            @apply bg-orange-500;
                        }

                        > ._label {
                            @apply text-orange-600;
                        }
                    }

                    > ._label {
                        @apply text-orange-500;
                    }
                }
            }
        }

        > ._mode {
            @apply pl-4 mt-1 border-main-500;
            background: transparent !important;

            > .un-menutab-item {
                &:hover {
                    background: transparent;

                    > ._label {
                        @apply text-main-500;
                    }
                }

                &.-active {
                    @apply border-main-500 border-b-0 bg-main-50;
                    &:before {
                        @apply bg-main-500;
                    }

                    > ._label {
                        @apply font-bold text-main-500;
                    }
                }

                > ._label {
                    @apply text-main-200;
                }
            }

            > ._line {
                @apply hidden;
            }
        }

        + ._form {
            @apply border-main-200 bg-main-50;
            &.-office {
                @apply border-orange-500 bg-orange-50;
            }

            .un-inputbox-input:not(:placeholder-shown) ~ .un-inputbox-label {
                @apply hidden;
            }
        }
    }

    > ._form {
        @apply p-2;
        > ._attach {
            @apply mt-2 flex items-start;
            > ._item {
                @apply ml-2 bg-white border rounded shadow hover:border-orange-500 hover:ring-2 hover:ring-opacity-20 hover:ring-orange-500;
                &.-img {
                    @apply relative;
                    > ._img {
                        @apply block h-16 w-16 text-xs bg-cover bg-center;
                    }

                    > .un-icon {
                        @apply absolute top-0 right-0;
                    }
                }

                &.-file {
                    @apply text-sm pl-4;
                    > ._name:hover {
                        @apply text-orange-500;
                    }
                }

                > .un-icon {
                    @apply ml-auto text-red-500;
                }
            }
        }

        > ._ai {
            @apply flex mb-2 items-center;
            > ._suggestion {
                @apply flex items-center border rounded-full bg-white px-2 cursor-pointer hover:border-blue-500;
                > ._icon {
                    @apply mr-2 text-blue-700;
                }

                > ._text {
                    @apply text-sm text-grey-600;
                }
            }

            > ._toggle {
                @apply ml-auto;
            }

            > ._view {
                @apply ml-2 mr-6 cursor-pointer hover:text-red-500;
            }
        }

        .chat-input {
            @apply bg-white;
            textarea {
                @apply outline-0;
            }
        }
    }
}

.chat-message-snippet {
    @apply flex flex-wrap overflow-y-auto p-2;
    width: 345px;
    height: 400px;

    &.-z50 {
        @apply z-50;
    }

    &.-from_modal {
        @apply z-50;
    }

    &.-snippet {
        @apply flex-col;
        ._item {
            @apply m-1 w-auto h-auto rounded bg-cover border hover:shadow cursor-pointer;
        }

        .un-menutab {
            @apply max-w-full;
            .un-menutab-item {
                @apply px-2;
                ._label {
                    @apply block truncate text-center;
                    max-width: 50px;
                }
            }
        }
    }

    &.-emoji {
        ._item {
            @apply m-1 w-9 h-9 text-center text-xl leading-8 rounded-lg border bg-cover bg-center hover:border-orange-500 hover:shadow-lg cursor-pointer;
        }
    }

    &.-sticker {
        ._item {
            @apply m-1 w-20 h-20 rounded-lg border bg-cover bg-center hover:border-orange-500 hover:shadow-lg cursor-pointer;
        }
    }
}

.chat-message-snippet-group {
    > ._group {
        @apply text-lg text-grey-blue-500 text-center;
    }
}

.chat-message-snippet-item {
    @apply flex rounded px-2 py-1 m-2 mt-2 cursor-pointer bg-grey-blue-50 hover:bg-orange-50 hover:ring-orange-500 hover:ring-2 hover:ring-opacity-50;
    > .un-icon {
        @apply block mt-1 mr-2 text-red-500;
    }
}

.chat-message-spliter {
    @apply mx-auto bg-grey-blue-50 text-grey-blue-600 px-3 my-3 rounded-full;
}

.chat-message-notify {
    @apply mx-auto bg-white border border-blue-400 text-sm text-blue-600 px-3 py-1 my-3 rounded-lg;
    > ._time {
        @apply text-blue-400 mr-2;
    }

    > ._user {
        @apply text-orange-600 mr-2 font-bold;
    }

    > ._link {
        @apply hover:text-red-600;
    }

    > ._text {
        @apply whitespace-pre-wrap;
    }

    > ._image {
        flex-basis: 100%;

        > img {
            @apply border rounded my-2 hover:border-orange-500;
            max-width: 300px;
            max-height: 300px;
        }
    }
}

.chat-message-boardcast {
    @apply mx-auto bg-white border border-purple-400 text-sm text-purple-600 px-3 py-1 my-3 rounded-lg;
    > ._head {
        @apply text-center whitespace-nowrap;
        > ._time {
            @apply text-purple-400 mx-2;
        }
    }

    > ._content {
        @apply whitespace-pre-wrap text-grey-blue-500;
    }
}

.chat-message-item {
    @apply relative flex hover:bg-green-50 p-2 mt-px items-start;

    &:hover {
        > .icon-delete {
            @apply block;
        }

        > ._on {
            @apply text-xs leading-tight;
            ._date {
                @apply block leading-none;
            }
        }
    }

    &.-me {
        @apply bg-blue-50 hover:bg-blue-100;
        > ._on {
            @apply text-right ml-3 mr-0;
            order: 2;
        }

        > ._content {
            order: 1;

            > ._user {
                @apply text-right;
            }

            > .text-content {
                @apply ml-auto mr-0 border-blue-300 shadow-none;
            }

            > ._sticker {
                @apply ml-auto;
            }

            > ._files {
                @apply justify-end;
            }
        }

        > .icon-delete {
            @apply absolute top-0 left-0 right-auto;
        }
    }

    &.-office, &.-office.-me {
        @apply bg-orange-50;
    }

    &.-office {
        @apply p-2 mt-2 ring-1 hover:bg-orange-100 ring-orange-500 rounded;
        > ._on {
            > ._time {
                @apply text-red-600;
            }
        }

        > ._content {
            &.-delete {
                > .text-content {
                    @apply italic text-orange-500 text-sm;
                }
            }

            > .text-content {
                @apply border-orange-400 shadow-none;
            }
        }
    }

    &.-wait {
        @apply bg-grey-100 hover:bg-grey-200;
        > ._on {
            > .un-icon {
                @apply text-base text-grey-400;
            }
        }

        > ._content {
            > .text-content {
                @apply border-grey-300 text-grey-300;
            }

            > ._sticker {
                > img {
                    @apply max-h-28 opacity-25 grayscale;
                }
            }
        }
    }

    > ._content {
        @apply flex-1 flex flex-col;

        > ._quote {
            @apply text-sm text-grey-600 mb-1;
            > ._icon {
                @apply text-xs font-bold mr-1;
            }
        }

        > .text-content {
            @apply border rounded py-2 px-4 bg-white shadow-lg inline-block mr-auto;
            max-width: 90%;

            + ._files {
                @apply mt-2;
            }
        }

        > ._files {
            @apply flex flex-wrap;
            > ._image {
                @apply w-24 h-24 mx-1 rounded shadow bg-cover bg-center hover:shadow-lg;
            }

            > ._file {
                @apply rounded shadow bg-white py-1 px-4 mx-1 truncate hover:shadow-lg;
                min-width: 100px;
                max-width: 120px;

                .un-icon {
                    @apply mr-2;
                }
            }
        }

        > ._readed {
            @apply text-right mt-1 text-xs text-red-600;
        }
    }

    > ._on {
        @apply text-sm mr-3;

        ._date {
            @apply text-grey-blue-600 hidden;
        }

        ._time {
            @apply text-grey-blue-500;
        }

        ._user {
            @apply text-blue-500;
        }
    }

    > .icon-delete {
        @apply absolute top-0 right-0 hidden;
    }
}

.chat-message-helper {
    @apply mr-auto border border-blue-200 rounded-xl bg-white shadow-lg;
    min-width: 250px;

    > ._header {
        @apply border-b border-blue-100 px-4 py-2 text-lg text-blue-400;
    }

    > ._content {
        @apply p-4;

        ._photo {
            @apply block border rounded w-10 h-10 mr-4 -my-2 -ml-2 bg-cover bg-center;
        }
    }

    > ._action {
        @apply flex p-3 justify-end items-center;
        > ._text {
            @apply px-2 py-1;
        }
    }
}


.chat-side-empty {
    @apply border border-orange-400 bg-orange-50 rounded-xl text-center text-orange-500 py-4 opacity-50;
}

.chat-document-header {
    @apply pr-1;
    > ._h {
        @apply flex items-center;
        > ._n {
            @apply flex-1 text-black;
        }

        > ._d {
            @apply text-sm;
        }
    }

    > ._info {
        @apply flex text-xs;
        > ._c {
            @apply flex-1 truncate;
        }

        > ._revoke {
            @apply text-red-500;
        }
    }

    > ._d {
        @apply flex text-xs;
    }
}

.chat-document-wait {
    @apply flex border rounded bg-white p-2 cursor-pointer hover:bg-grey-blue-50;
    + .chat-document-wait {
        @apply mt-3;
    }

    > .un-icon {
        @apply flex items-center text-2xl text-grey-500 w-8;
    }

    > ._detail {
        @apply flex-1;
        > ._h {
            @apply flex items-center;
            > ._n {
                @apply flex-1 text-black;
            }

            > ._d {
                @apply text-sm;
            }
        }

        > ._c {
            @apply text-xs truncate;
        }

        > ._d {
            @apply flex text-xs;
        }
    }
}

.chat-document-order {
    @apply flex border rounded bg-white p-2 cursor-pointer hover:bg-grey-blue-50;
    + .chat-document-wait {
        @apply mt-3;
    }

    > .un-icon {
        @apply flex items-center text-2xl text-grey-500 w-8;
    }

    > ._detail {
        @apply flex-1;
        > ._h {
            @apply flex items-center;
            > ._n {
                @apply flex-1 text-red-500 font-bold;
            }

            > ._d {
                @apply text-sm text-blue-500;
            }
        }

        > ._info {
            @apply flex items-center;
            > ._t {
                @apply font-bold mr-2 leading-5;
            }

            > ._c {
                @apply flex-1 text-xs truncate leading-5;
            }
        }
    }
}

.chat-document {
    @apply border rounded bg-white p-1;
    + .chat-document {
        @apply mt-3;
    }

    &.-close {
        .un-collapse-header {
            @apply border-b-0;
        }
    }

    .un-collapse-header {
        @apply p-1 border-b border-dotted;
    }
}

.chat-call {
    @apply flex items-center border rounded bg-white p-1;
    + .chat-call {
        @apply mt-2;
    }

    > ._name {
        @apply w-1/4 text-center;
        > ._type {
            @apply text-xs text-green-600;
        }
    }

    > ._time {
        @apply flex-1;
        > ._time {

        }

        > ._duration {
            @apply flex text-xs text-blue-600;
            > ._status {
                @apply ml-2 text-grey-600;
            }
        }
    }

    > ._icon-download {

    }
}

.chat-document-item {
    @apply pr-1 py-1 rounded cursor-pointer hover:bg-grey-blue-50;
    > ._h {
        @apply flex items-center;
        > ._r {
            @apply w-11 pr-2 text-right;
        }

        > ._n {
            @apply flex-1 text-black text-red-500;
        }

        > ._d {
            @apply text-sm text-blue-500;
        }
    }

    > ._info {
        @apply flex pl-11 text-xs text-grey-blue-500;
        > ._c {
            @apply flex-1 truncate;
        }

        > ._revoke {
            @apply text-red-500;
        }
    }

    .btn-edit {
    }
}

.chat-sidebar {
    @apply w-96 h-screen border-none rounded-none shadow-2xl;

    .no-sidebar {
        @apply hidden;
    }

    .un-modal-body {
        @apply flex flex-col my-0 flex-1;
        > .un-form-container:first-child {
            @apply pt-0;
        }
    }

    .un-modal-title, .un-modal-title-text {
        @apply truncate;
    }

    .un-modal-footer {
        .un-button {
            .un-icon + ._text {
                @apply hidden;
            }
        }
    }

    .un-form-container {
        @apply flex-col;
        &.-collapse.-collapse-close {
            @apply flex-col;
            .un-form-header {
                @apply flex-col mb-0;
                .un-form-label {
                    @apply w-full;
                }
            }

            .un-form-header-collapse {
                @apply pl-12 ml-0;
            }
        }

        > .un-form-header {
            @apply w-auto mb-6 max-w-none;
        }
    }

    .w-fill .flex-on {
        display: block !important;

        .ml-4 {
            margin-left: 0 !important;
        }

        .-fix-width, .w-40, .w-60, .w-1\/3 {
            width: auto !important;
        }

        .un-input-date {
            display: flex !important;
        }
    }

    .row {
        display: block !important;
        margin: 0;

        .col {
            width: auto !important;
            padding: 0 !important;
        }
    }

    ._input-flex-row {
        @apply block;
        .un-input-radio + .un-inputbox, .un-inputbox + .un-inputbox {
            @apply mt-6;
        }

        > .un-inputbox, > .un-input-radio {
            width: auto !important;
            margin-left: 0 !important;
        }
    }

    ._input-photo-stack {
        @apply block;
        .document-customer-photo + .document-customer-photo {
            @apply mt-6 ml-0;
        }
    }

    .customer-vehicle {
        @apply -mx-px;
        .flex-on {
            @apply flex-wrap;
        }
    }

    .coverage {
        @apply flex-col;
        .coverage-detail {
            @apply w-auto border-none;
        }
    }

    .docform-book-input {
        @apply flex-col;
        .un-inputbox {
            @apply mb-6;
            width: auto !important;
            margin-left: 0 !important;
        }
    }

    .docform-input-new {
        @apply flex-wrap;
        .un-input-radio {
            @apply w-full mb-0;
        }

        .un-button {
            @apply mb-0;
            .un-icon + ._text {
                @apply hidden;
            }
        }

        .un-boxmargin {
            @apply mb-0;
        }
    }

    .docform-customer {
        @apply block;
        > .docform-customer-form {
            + .docform-customer-form {
                @apply mt-6 ml-0;
            }

            > ._header {
                @apply flex-wrap;
                > ._div {
                    @apply block w-full h-2;
                }
            }
        }
    }

    .docform-vehicle ._input-mb-6 {
        @apply mb-6;
    }

    .docform-insurance {
        ._vehicle_usages {
            @apply flex-wrap;
            > .un-input {
                margin-left: 0 !important;
            }

            ._vehicle-mt {
                @apply -mt-2;
            }

            ._addition-mt {
                @apply mt-2;
            }
        }
    }

    .docform-insurance-old {
        .no-boxmargin {
            .un-inputbox {
                @apply mt-6;
            }
        }
    }

    .docform-pay {
        ._input-last {
            .un-input, .un-input-text-mb {
                @apply mb-6;
            }
        }
    }

    .docform-attach {
        ._item {
            @apply justify-between;
            > .un-input-photo {
                margin-right: 0 !important;
            }
        }

        .document-attach {
            @apply block px-2;
            > ._box {
                @apply my-1.5 mx-0 w-auto;
            }

            > ._line {
                @apply w-auto;
            }
        }
    }

    .document-paylots {
        .un-list-head {
            @apply hidden;
        }

        .un-list {
            @apply relative pl-12 block;
            > .w-rownum {
                @apply absolute font-bold text-xl top-2 -left-2;
            }

            > .un-inputbox {
                @apply flex mx-0 mt-1;
                width: auto !important;
            }
        }

        ._header {
            @apply flex justify-between;
            > strong {
                @apply mx-0 text-red-600;
            }

            > span {
                @apply hidden;
            }
        }
    }

    .document-paylot {
        > ._header {
            @apply block;
            > ._count, > ._summary {
                @apply text-center;
            }
        }

        > ._lots {
            > ._head {
                > ._lot {
                    @apply text-center w-full;
                }

                > :not(._lot) {
                    @apply hidden;
                }
            }

            > ._item {
                @apply block;
                > ._lot {
                    @apply m-0 w-full flex items-center justify-center;
                    > ._number {
                        @apply mr-3;
                    }
                }

                > ._due, > ._pay {
                    @apply m-0 w-full mt-2;
                    > ._box {
                        @apply flex-wrap;
                        > ._detail {
                            @apply w-full;
                            > ol {
                                @apply min-h-0;
                                > li {
                                    @apply text-right;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .book-view-attach {
        > ._label {
            @apply w-24 truncate;
        }

        > ._items {
            @apply block truncate;
        }
    }

    .book-view-attach-item {
        @apply w-auto;
    }

    ._input-line {
        @apply mb-6;
    }
}

.chat-sidebar, .chat-field-vehicle {
    .book-vehicle-selector {
        @apply relative flex-col border rounded mr-10;
        > .un-input-select {
            @apply w-full border-0;

            + .un-input-select {
                @apply border-t border-dotted;
            }

            .s__single-value {
                @apply ml-10;
            }
        }

        > .un-icon {
            @apply absolute top-0 -right-10;
        }
    }
}

.chat-field-vehicle {
    .book-vehicle-selector {
        @apply mt-6 mb-3;
    }
}

.chat-field-vehicle-item {
    ._year {
        @apply bg-orange-50 text-orange-600 self-center h-10 w-10 flex items-center justify-center;
        margin-left: .25rem !important;
        margin-right: .25rem !important;
    }

    ._gen {
        @apply text-blue-800;
        span + span {
            @apply text-blue-500 ml-2;
        }
    }

    ._subgen {
        @apply text-xs text-grey-blue-500;

    }
}


.chat-boardcast-form {
    ._total-count {
        @apply border rounded mb-4 py-2 px-4 border-orange-500 bg-orange-50 text-orange-600 text-center;
    }

    ._input-content textarea {
        min-height: 300px;
    }
}

.chat-boardcast-option {
    @apply mt-2;
}

.chat-liff-item {
    @apply flex items-center border rounded-md p-2 bg-white hover:border-green-300;

    + .chat-order-item {
        @apply mt-4;
    }

    > ._image {
        @apply w-10 h-10 mr-3;
        border-radius: 50px;
    }

    > ._detail {
        @apply flex-1;
        > ._time {
            @apply text-grey-blue-500 text-sm;
        }
    }
}
