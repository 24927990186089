.dashboard-widgets {
    @apply flex flex-wrap;
}

.dashboard-widget {
    @apply relative mb-4 px-2;
    min-height: 100px;

    &.-size-1 {
        @apply w-1/3;
    }

    &.-size-2 {
        @apply w-2/3;
    }

    &.-size-3 {
        @apply w-full;
    }

    &.-edit:hover {
        @apply bg-grey-blue-100;
        > ._header {
            @apply flex absolute rounded-md bg-white bg-opacity-90 p-2 top-2 left-2;
            > .un-icon {
                @apply flex items-center justify-center text-2xl w-10 h-10;
            }
        }
    }

    > ._header {
        @apply hidden z-10 shadow-lg;
        .un-icon-move {
            @apply text-grey-500 cursor-move hover:text-red-500;
        }
    }
}

.dashboard-mock {
    @apply border rounded-md bg-grey-50 text-grey-500 text-2xl text-center p-4;
}



.dashboard-tasks {
    @apply flex flex-col ml-4;
    width: 400px;

    > ._head {
        @apply text-center text-lg text-grey-blue-500 mb-2;
    }

    ._item {
        @apply border rounded py-1 pl-2 pr-1 text-sm flex cursor-pointer hover:ring-2 hover:ring-orange-200;

        + ._item {
            @apply mt-2;
        }

        &.-active {
            @apply ring-2 ring-blue-200;
        }

        @each $color in $color-names {
            &.-#{$color} {
                @apply border-#{$color}-500 bg-#{$color}-50;
                > ._name {
                    @apply text-#{$color}-700;
                }
            }
        }

        > ._name {
            @apply flex-1;
        }

        > ._from {
            @apply mr-2 text-blue-500;
        }

        > ._time {
            @apply text-grey-blue-500;
            + ._from {
                @apply ml-2 mr-0;
            }
        }

        > ._cost {
            @apply w-10 ml-2 bg-white text-right text-red-500 text-xs py-0.5 px-1 rounded overflow-hidden;
        }
    }
}

.dashboard-task-item {
    ._item {
        @apply border rounded py-1 px-2 mt-2 text-sm flex cursor-pointer hover:ring-2 hover:ring-orange-200;

        &.-active {
            @apply ring-4 ring-blue-200;
        }

        @each $color in $color-names {
            &.-#{$color} {
                @apply border-#{$color}-500 bg-#{$color}-50;
                > ._name {
                    @apply text-#{$color}-700;
                }
            }
        }

        > ._time {
            @apply text-grey-blue-500;
            + ._from {
                @apply ml-2 mr-0;
            }
        }

        > ._cost {
            @apply ml-auto bg-red-50 text-red-500 text-xs py-0.5 px-1 rounded;
        }
    }
}

.dashboard-task-log {
    overflow: auto;
    max-height: 500px;
}

.dashboard-task {
    @apply relative flex flex-1 flex-col border p-px;

    > ._item-header {
        @apply flex text-sm h-5 text-grey-blue-300;
        > ._name {
            @apply text-center w-32;
        }

        > ._times {
            @apply flex flex-1 ml-px;
            > ._h {
                @apply flex-1;
                > ._text {
                    @apply pl-2;
                }

                > ._line {
                    @apply absolute inset-y-0 border-l border-dashed;
                }
            }
        }

        > ._last {
            @apply w-14 text-center border-l border-dashed;
        }
    }

    > ._item-list {
        ._item {
            @apply flex hover:bg-orange-100;
            > ._name {
                @apply truncate text-xs leading-6 px-1 w-32 text-grey-blue-500 border-t border-dotted border-grey-blue-200;
            }

            > ._times {
                @apply flex-1 relative border-t ml-px;
                > ._time {
                    @apply absolute top-1 bottom-1 bg-green-500 opacity-50 cursor-pointer;
                    min-width: 4px;

                    &:hover {
                        opacity: 1;
                    }

                    &.-slow1 {
                        @apply bg-orange-500;
                    }

                    &.-slow2 {
                        @apply bg-red-400;
                    }

                    &.-slow3 {
                        @apply bg-red-500;
                    }

                    &.-slow4 {
                        @apply bg-red-600;
                    }

                    &.-past {
                        @apply bg-blue-500;
                    }
                }
            }

            > ._last {
                @apply text-grey-blue-500 text-center text-sm leading-6 w-14 border-t border-l;
            }
        }
    }
}

.dashboard-task-view-detail {
    @apply relative w-full mt-1 mb-3 rounded p-2 bg-grey-800 text-white h-auto overflow-y-hidden whitespace-pre-wrap;
    max-height: 100px;
    outline: none;

    &.-exp {
        max-height: none;
    }
}

.dashboard-links {
    @apply flex mt-4;
    > ._item {
        @apply flex flex-1 items-center rounded p-1 bg-grey-blue-50 border border-grey-blue-300;
        + ._item {
            @apply ml-4;
        }

        > ._title {
            @apply flex-1 pl-2;
        }

        > ._link {
            @apply text-sm bg-grey-blue-500 text-white px-3 rounded-md;
            + ._link {
                @apply ml-1;
            }

            &.-orange {
                @apply bg-orange-600 hover:bg-orange-700;
            }

            &.-green {
                @apply bg-green-600 hover:bg-green-700;
            }

            &.-red {
                @apply bg-red-600 hover:bg-red-700;
            }
        }
    }
}



.home-btns {
    @apply flex flex-wrap justify-center;
}

.home-btn {
    @apply border block m-2 rounded-lg py-4 text-center w-40;
    &:hover {
        @apply shadow-lg;
        color: $mainColor;
    }

    > .un-icon {
        @apply block text-5xl leading-relaxed;
        background: linear-gradient(to bottom, #01edd5 0%, #02abe9 100%);
    }

    > span {
        @apply block mt-2 text-lg;
    }
}

.app-body {
    .home-btn {
        > .un-icon {
            -webkit-text-fill-color: transparent !important;
            -webkit-background-clip: text !important;
            background-clip: text !important;
        }
    }
}

.home-emp-stats {
    @apply border rounded p-3;
}

.home-emp-item {
    @apply rounded-lg shadow-md p-3 mr-4 w-32 text-center;

    .un-icon {
        @apply text-4xl my-4;
    }

    ._money {
        @apply text-grey-blue-500;
    }

    ._count {
        @apply text-xl;
    }
}

.home-emp-summary {
    @apply w-72 text-right;
    > ._item {
        @apply flex items-center;

        > ._name {
            @apply w-32;
        }

        > ._value {
            @apply flex-1;
            &.-xl {
                @apply text-2xl;
            }
        }
    }
}

.home-emp-state {
    @apply flex rounded border bg-white;

    > ._item {
        @apply flex-1 text-center py-2;
        + ._item {
            @apply border-l border-dotted;
        }

        .un-icon {
            @apply text-4xl my-2;
        }

        ._name {
            @apply text-sm text-grey-blue-500;
        }

        ._count {
            @apply text-xl text-red-500;
        }
    }
}


.support-label {
    @apply bg-grey-blue-500 text-white px-2 rounded text-sm flex items-center justify-center;
    margin-right: 0 !important;
    @each $c in $color-lists {
        &.-#{$c} {
            @apply bg-#{$c}-500;
        }
    }
}


.support-view {
    @apply flex mb-2 -mx-2 -mt-3;
    height: 700px;

    > ._content {
        @apply relative flex flex-1 flex-col;
        > ._detail {
            @apply relative bg-grey-blue-50 flex-1 rounded;
            .simplebar-content-wrapper {
                @apply p-2;
            }
        }
    }


    ._file-item {
        @apply mb-1 flex px-2;

        + ._detail {
            @apply mt-2;
        }

        > .un-icon {
            @apply w-4 text-center mt-1 mr-2;
        }

        > a {
            @apply hover:text-red-500;
        }
    }

    > ._comment {
        @apply ml-3 flex flex-col w-96;
        > ._list {
            @apply relative flex-1 bg-grey-50 rounded;
        }

        > ._form {
            @apply mt-2;

            .un-inputbox {
                @apply mb-0;
            }

            textarea {
                @apply resize-none;
            }
        }
    }

    ._comment-item {
        @apply p-2 border-b border-white flex hover:bg-sky-50;
        > ._member {
            @apply mr-4;
            > img {
                @apply h-8 w-8 rounded-full;
            }

            > ._name {
                @apply text-center text-xs w-8 mt-2 truncate;
            }
        }

        > ._detail {
            @apply flex flex-col flex-1;
            > ._text {
                @apply mb-2;
            }

            > ._by {
                @apply flex text-xs text-right;
                > ._user {
                    @apply text-blue-700 font-bold;
                }

                > ._from {
                    @apply text-grey-500 ml-auto;
                }

                > ._date {
                    @apply ml-2 text-grey-blue-500;
                }
            }
        }
    }
}
