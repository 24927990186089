.test-dnd-con {
    @apply border w-80 mx-auto mb-6;
    min-height: 500px;
}

.test-dnd-item {
    @apply flex p-1 border m-1;
    &.-drag {
        @apply border-blue-500 bg-blue-50;
    }
}

.test-chatroom {
    @apply flex flex-1 -m-2;
    > ._room {
        @apply flex flex-col flex-1 bg-grey-blue-50 rounded-lg;
    }

    > ._config {
        @apply flex flex-col w-1/2 ml-2 p-2;

        > ._profile {
            @apply flex items-center mb-2;
        }

        > ._way {
            @apply flex flex-col flex-1 p-2 -mb-2 mt-2 bg-grey-blue-50 rounded-lg;
            > .test-chatroom-form-setting {
                @apply border rounded-md mt-4;
                > .un-menutab {
                    @apply pt-1 bg-grey-blue-200;
                    > .un-menutab-item {
                        @apply text-white hover:text-grey-blue-100;
                        &.-active {
                            @apply bg-white text-black;
                        }
                    }
                }

                > ._input-textarea {
                    @apply mt-0 border-none;
                }
            }
        }
    }

    .chat-form {
        @apply flex flex-col;
        width: 600px;

        .chat-message-form {
            @apply flex flex-col flex-1 border rounded-xl p-4;

            .message-list {
                @apply flex;

                .message-item {
                    @apply text-sm mt-2 border rounded-l p-2 border-green-200;
                }

                .me {
                    @apply ml-auto  text-right;
                }
            }

        }


    }

    .mock {
        @apply border-none bg-transparent;
    }
}

.test-chatroom-info {
    @apply flex items-center m-2;

    > ._way {
        @apply px-2 py-px text-sm text-white bg-blue-500 rounded-md mr-2;
    }

    > ._model {
        @apply w-60 p-0;
    }

    > ._size {
        @apply text-sm text-green-600 mx-2;
    }

    > ._debug-note {
        @apply flex-1 pl-2 py-1.5 text-sm text-grey-500 leading-none bg-white rounded-md;
    }

    > ._btn {
        @apply p-0 min-h-0 mr-2;
    }
}

.test-chatroom-messages {
    @apply flex-1 relative;
    > ._box {
        @apply absolute inset-0 overflow-y-auto flex flex-col-reverse p-2;
    }
}

.test-chatroom-message {
    @apply flex flex-col;

    + .test-chatroom-message {
        @apply mt-2;
    }

    &.-me {
        &:hover > ._content > ._icon-reset {
            @apply block;
        }

        > ._content {
            @apply ml-auto text-right bg-green-100;
        }

        > ._foot {
            @apply justify-end;
            > ._time {
                @apply text-green-500;
            }
        }
    }

    &.-ai {
        > ._content {
            @apply mr-auto;
        }

        &.-req > ._content {
            @apply border border-grey-blue-200;
        }

        &:hover > ._content > ._icon-request {
            @apply block;
        }
    }

    > ._head {
        @apply flex items-center mb-1;

        > ._way {
            @apply mr-2 text-blue-600 leading-none;
        }

        > ._btn + ._btn {
            @apply ml-1;
        }
    }

    > ._content {
        @apply relative rounded-xl p-1 bg-grey-blue-100;
        max-width: 75%;

        > ._icon-reset {
            @apply hidden absolute -left-4 -top-4 bg-green-500 text-white hover:bg-green-700;
        }

        > ._icon-request {
            @apply hidden absolute -right-4 -top-4 bg-grey-blue-500 text-white hover:bg-grey-blue-700;
        }

        > ._photos {
            @apply flex justify-end p-1;
            > ._item {
                @apply block w-12 h-12 rounded-xl bg-center bg-cover cursor-pointer hover:ring-2 hover:ring-blue-500 hover:ring-opacity-75;
                + ._item {
                    @apply ml-2;
                }

                > ._img {
                    @apply w-full h-full object-cover rounded-xl;
                }
            }
        }

        > ._text {
            @apply px-2 py-1;
        }
    }

    > ._view {
        @apply my-2;
    }

    > ._raw {
        @apply italic text-xs mb-2 text-grey-500 bg-grey-blue-50 border p-0.5 rounded-md;
    }

    > ._foot {
        @apply flex;
        > ._time {
            @apply text-sm text-grey-blue-500 px-2;
        }

        > ._usage {
            @apply text-sm px-2;
            .un-icon {
                @apply text-xs;
            }

            span + span {
                @apply ml-2;
            }
        }
    }
}


.test-chatroom-form {
    @apply relative bg-grey-blue-100 rounded-lg p-2 m-2;
    > ._wait {
        @apply absolute z-10 inset-0 min-h-0 p-0 bg-grey-blue-500 rounded-lg;
        .un-icon {
            @apply text-5xl;
        }
    }

    ._attach {
        @apply flex mb-2;
        > ._item {
            @apply w-12 h-12 border rounded relative hover:ring-2 hover:ring-blue-500 hover:ring-opacity-25;
            + ._item {
                @apply ml-2;
            }

            &:hover {
                > ._drop {
                    @apply block;
                }
            }

            > ._drop {
                @apply z-10 absolute -top-4 -right-4 bg-red-500 text-white hidden;
            }

            > ._img {
                @apply w-full h-full object-cover bg-white rounded-md;
            }
        }
    }

    > ._form {
        @apply flex;
        > .un-input {
            @apply flex-1;
        }

        > .un-input-upload-button {
            @apply ml-2;
        }

        > .un-button {
            @apply ml-4;
        }
    }
}

.test-chatroom-form-setting {
    @apply flex flex-1 flex-col;
    > ._input-textarea {
        @apply mt-3 flex-1 border p-2 rounded focus:outline-0 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-25;
    }
}

.test-chatroom-form-ways {
    @apply p-1 mt-1 pt-1 border-t border-dashed overflow-y-auto;
    max-height: 75%;

    .un-form-item + .un-form-item {
        @apply mt-1;
    }
}

.test-chatroom-profile {
    @apply grid grid-cols-2 rounded-md;
    > ._input {
        @apply flex items-center p-1 rounded-md hover:bg-white;
        &.-active {
            @apply bg-blue-100;
            > ._label {
                @apply font-bold text-blue-600;
            }

            > ._input {
                @apply border-blue-500;
            }
        }

        > ._label {
            @apply w-36 text-sm text-right text-grey-700 leading-none pr-3;
            &.-click {
                @apply cursor-pointer hover:text-red-500;
            }
        }

        > ._input {
            @apply flex-1;
        }
    }
}

.test-chatroom-action_detail {
    @apply bg-white mr-auto p-2 border rounded-xl;
    > .detail-item {
        @apply my-0;
        + .detail-item {
            @apply mt-1;
        }

        > ._label {
            @apply w-24;
        }

        > ._value {
            @apply pr-4;
        }
    }
}

.test-chatroom-action_item-selection {
    @apply flex items-start flex-wrap;

    > ._item {
        @apply bg-white border m-1 p-1 text-sm rounded-md;

        > ._insurance {
            @apply flex;
            width: 150px;

            > ._logo {
                @apply w-8 h-8 object-cover m-1 mr-2 rounded-full;
            }

            > ._detail {
                @apply flex flex-col flex-1 truncate;
                > ._name {
                    @apply truncate font-bold;
                }

                > ._info {
                    @apply flex justify-between;
                    > ._type {
                        @apply font-bold text-blue-500;
                    }

                    > ._price {
                        @apply text-red-500;
                    }
                }
            }
        }

        > ._button {
            @apply bg-grey-blue-50 border m-1 py-1 px-2 text-center text-sm rounded-md cursor-pointer hover:bg-blue-50 hover:border-blue-200;
        }
    }
}

.test-chatroom-viewtext {
    @apply bg-grey-blue-50 -m-2 p-4 rounded-md whitespace-pre-wrap;
}

